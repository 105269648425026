.Terminal-Configuration {font-size: 20px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.2 !important;
    letter-spacing: 0.15px !important;
    text-align: left;
    color: #000; text-transform: capitalize !important;}
    .p-e-date{ padding: 0px !important;}
    .chef-component{ padding-left: 0px !important; 
        display: flex;
        width: 100%;
        flex-wrap: wrap;}
    .Terminal-Configuration-m div{ box-shadow: none !important;}
    .chef-com{ margin-bottom: 10px !important;}
    .Terminal-Configuration-m { padding: 20px !important;}

  .tg-con div label{ background: #fff;border-radius: 10px; font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.15px;
    text-align: left;
    color: rgba(0, 0, 0, 0.6) !important;}
    .tg-label{font-size: 13px !important;
        font-weight: normal !important;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: 0.16px;
        text-align: left;
        color: #000;
      }
      .m-t{ padding: 15px !important;}
      @media (max-width:700px) {
        .m-t{ width:90% !important; padding: 15px !important;}
        }

        .tg-con11 div label{ background: #fff;border-radius: 10px; font-family: Roboto;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.15px;
          text-align: left;
          color: rgba(0, 0, 0, 0.6) !important;}
          .tg-label{font-size: 13px !important;
              font-weight: normal !important;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.38;
              letter-spacing: 0.16px;
              text-align: left;
              color: #000;
            }
            .tg-con14 div label{ background: #fff;border-radius: 10px; font-family: Roboto;
              background: #fff;
              border-radius: 10px;
              font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);}
              .tg-label{font-size: 13px !important;
                  font-weight: normal !important;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.38;
                  letter-spacing: 0.16px;
                  text-align: left;
                  color: #000;
                }
                
                .tg-con14 .Mui-focused{

                  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.15px;
  text-align: left;
  color: rgba(0, 0, 0, 0.6) !important;
                }
            .m-t{ padding: 15px !important;}
            @media (max-width:700px) {
              .m-t{ width:90% !important; padding: 15px !important;}
              }
    
  .chef-com{font-size: 13px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.16px;
    text-align: left;
    color: rgba(0, 0, 0, 0.87); margin-right:10px !important;}
    .heading-modal{
      font-size: 20px !important;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: 0.15px;
      text-align: left;
      color: #000;
    text-transform: capitalize !important;}
    .v2 thead{ box-shadow: none !important;}

.terminals-modern{
  @media(max-width:767px){
  max-height: 75vh;
  overflow-y: auto;
  }
}


