@import "~bootstrap/scss/bootstrap.scss";

.red {
  color: #4caf50;
}

.react-tag-input {
  background: white;
  color: #333;
  border: 1px solid #e1e1e1;
}
.react-tag-input__input {
  background: transparent;
  color: #333;
  height: 38px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 10px 0 !important;
}
.react-tag-input__input::placeholder,
.react-tag-input__input:-moz-placeholder,
.react-tag-input__input:-ms-input-placeholder,
.react-tag-input__input::-moz-placeholder,
.react-tag-input__input::-webkit-input-placeholder {
  color: #333; /* Input placeholder */
}
.react-tag-input__tag {
  background: #e1e1e1;
  border-radius: 30px !important;
  padding: 3px 4px !important;
}
.react-tag-input__tag__remove {
  background: #d4d4d4;
  border-radius: 100px !important;
}
.react-tag-input__tag__remove:before,
.react-tag-input__tag__remove:after {
  // content: "x";
  background: #333;
  color:white /* X icon in remove button */
}
