.h-m {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  box-shadow: 0 5px 5px 0 rgba(#000, 0.1);
  background: #fff;
}

.top-header {
  align-items: center;
  justify-content: center;

  a {
    color: #007bff;
    text-decoration: none;
  }
}

.login-sec {
  padding: 10rem 0;
  display: block;
  position: relative;
  padding: 5rem 0;
  width: 100%;

  .logreg-form {
    max-width: 400px;
    margin: 0 auto;

    .top1 {
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;

      h3 {
        font-size: 24px;
        color: #7b7b7b;
        margin-bottom: 0;
      }
    }

    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 15px;
    }

    .p-text {
      font-size: 16px;
      color: #7b7b7b;
      margin-top: 20px;
    }

    .bottom-mail {
      position: relative;
      padding-top: 15px;
    }

    .rembox-button {
      background-color: #219fe8 !important;
      -webkit-box-shadow: none;
      box-shadow: none;
      border: none;
      border-radius: 5px;
      padding: 10px 15px;
      line-height: 1;
      color: #fff;
      position: absolute;
      right: 0;
      top: 15px;
    }
  }
}

.footer-main {
  padding: 5rem 0;
  background: #111;
  position: relative;
  z-index: 9;

  @media (max-width: 1200px) {
    padding: 25px 0;
  }

  .foo-textblock a img {
    max-width: 200px;
    width: auto;
  }

  .widget-title {
    font-size: 24px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 1rem;
  }

  .foo-nav li a {
    color: #fff;
    font-size: 15px;
  }

  .foo-widget {
    position: relative;
    margin-bottom: 30px;

    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
      background: #fff;
      border: none !important;
      box-shadow: none;
      padding: 7px 10px;
      border-radius: 2px;
      width: 100%;
    }

    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
      border: 0px;
    }

    .newsletter-button {
      position: absolute;
      right: 0;
      top: 0;
      border: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      height: 100%;
      padding: 10px 20px;
      font-size: 14px;
      border-radius: 0px 2px 0px 2px;
      // border-top-right-radius: 2px;
      // border-bottom-right-radius: 2px;
      background: #219fe8;
      color: #fff;
    }

    input[type="text"] {
      background: #fff !important;
      width: 100% !important;
      border: 0px !important;
    }

    div {
      width: 100% !important;
      border-radius: 2px !important;
      border: 0;
      padding: 0px !important;
      label {
        background: #fff;
      }
    }
  }

  .footer-last h4 {
    color: #fff;
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 500;
  }
}
.log-top-logo {
  padding-top: 0px !important;
}
.login-head {
  text-align: left;
  @media (max-width: 599px) {
    text-align: center;
  }
}

.login-head1 {
  text-align: right;
  @media (max-width: 599px) {
    text-align: center;
  }
}
@media (max-width: 599px) {
  .b-space {
    padding-bottom: 25px;
  }
}
.top1 form {
  width: 100%;
}
.top1 form > div {
  width: 100%;
}
.error-text {
  color: red;
}
.top1 div {
  margin-left: 0px !important;
}

.logo {
  svg {
    width: auto;
    height: auto;
  }
}
