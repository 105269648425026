*{ padding: 0px;
    margin: 0px;}
    body,html{
        width: 100%;
    }
    body {
        margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    font-weight: normal;
    font-style: normal;
    color: #000;
    font-size: 18px;
    line-height: 1.5;
    background-color: #fff;
    background-position: top;
    }
    ul { padding: 0px; margin: 0px; list-style-type: none;}
    b,
    strong {
        font-weight: 700;
    }
    
    p {
        margin: 0 0 22px 0;
    }
    
    p:last-child {
        margin-bottom: 0;
    }
    
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0 0 25px 0;
        padding: 0;
        color: #000;
        font-weight: 700;
        font-family: 'Roboto', sans-serif;
    
    
    }
    
    h1:last-child,
    h2:last-child,
    h3:last-child,
    h4:last-child,
    h5:last-child,
    h6:last-child {
        margin-bottom: 0;
    }
    
    
    h2 {
        font-weight: 700;
        font-size: 80px;
        line-height: 1;
        text-transform: uppercase;
      
    }
    

    
   
    
    h4 {
        font-size: 24px;
        line-height: 1.2;
       
    }
    
    
    
    
    
    a {
        margin: 0;
        padding: 0;
        text-decoration: none !important;
        outline: none !important;
        color: #ecb61e;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    
    a:hover {
        color: red;
    }
    
    img {
        max-width: 100%;
    }
    
    input[type="text"],
    input[type="tel"],
    input[type="password"],
    input[type="email"],
    select {
        background-color: transparent;
        width: 100%;
        -webkit-appearance: none;
        appearance: none;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        border-radius: 0;
        color: #000;
        //border: 1px solid rgba(255, 255, 255, 0.7);
        //height: 58px;
        // padding:8px 16px;
        outline: none !important;
    }
    
    textarea {
        background-color: transparent;
        -webkit-appearance: none;
        appearance: none;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        border-radius: 0;
        border: 1px solid rgba(255, 255, 255, 0.7);
        padding: 12px 16px;
        width: 100%;
        color: #000;
        height: 158px;
        outline: none !important;
    }
    
    select {
        border: 1px solid rgba(255, 255, 255, 0.7);
        width: 100%;
        padding: 0 11px;
        //background: url(images/arrow_bottom.svg) no-repeat calc(100% - 16px) center transparent;
        background-size: 18px;
        padding-right: 40px;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;
    }
    
    select::-ms-expand {
        display: none;
    }
    
    input[type="submit"] {
        background: #eab71e;
        color: #000000;
        height: 58px;
        border: none;
        -webkit-appearance: none;
        appearance: none;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        border-radius: 0;
        padding-left: 30px;
        padding-right: 30px;
        text-transform: uppercase;
        cursor: pointer;
        font-size: 24px;
        font-weight: 700;
        outline: none !important;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    
    input[type="submit"]:hover {
        background: #000;
        color: #eab71e;
    }
    
     ::-webkit-input-placeholder {
        opacity: 0.7;
        color: #000;
    }
    
     :-ms-input-placeholder {
        opacity: 0.7;
        color: #000;
    }
    
     ::-moz-placeholder {
        opacity: 0.7;
        color: #000;
    }
    
     :-moz-placeholder {
        opacity: 0.7;
        color: #000;
    }
    
    
    
    
    
    
    
    /* images alignment for wordpress content pages */
    .alignright {
        float: right;
        margin: 0 0 20px 20px;
    }
    
    .alignleft {
        float: left;
        margin: 0 20px 20px 0;
    }
    
    .aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        float: none;
        clear: both;
    }
    
    
    
    /* custom checkbox */
    
    .form_input_check label {
        position: relative;
        margin: 0;
        padding-left: 40px;
        display: inline-block;
    }
    
    .form_input_check label span {
        margin: 0;
        padding: 0;
    }
    
    .form_input_check label input[type="checkbox"] {
        visibility: hidden;
        display: none;
    }
    
    .form_input_check label input[type="checkbox"]+span::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0px;
        width: 22px;
        height: 22px;
        border: 1px solid #3E3E42;
        background: transparent;
    }
    
    .form_input_check label input[type="checkbox"]+span::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0px;
        width: 22px;
        height: 22px;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        //background: url(images/tick.svg) no-repeat center center;
        background-size: 15px;
    }
    
    .form_input_check label input[type="checkbox"]:not(:checked)+span::after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    
    
    /* .form_input_check input[type="checkbox"]:checked + span::before {
          
        } */
    
    .form_input_check label input[type="checkbox"]:checked+span:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    
    
    /* custom radio */
    
    .form_input_radio label {
        position: relative;
        margin: 0;
        padding-left: 30px;
        display: inline-block;
    }
    
    .form_input_radio label span {
        margin: 0;
        padding: 0;
    }
    
    .form_input_radio label input[type="radio"] {
        visibility: hidden;
        display: none;
    }
    
    .form_input_radio label input[type="radio"]+span::before {
        content: '';
        position: absolute;
        left: 0;
        top: 1px;
        width: 22px;
        height: 22px;
        border: 2px solid #000000;
        border-radius: 100%;
        background: #fff;
    }
    
    .form_input_radio label input[type="radio"]+span::after {
        content: '';
        width: 12px;
        height: 12px;
        background: #4b0049;
        position: absolute;
        top: 6px;
        left: 5px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    
    .form_input_radio label input[type="radio"]:not(:checked)+span::after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    
    
    /* .form_input_radio input[type="radio"]:checked + span::before {
          
        } */
    
    .form_input_radio label input[type="radio"]:checked+span:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    
    
    /* custom radio end */
    
    /* back to top */
    
    #scroll {
        position:fixed;
        right:20px;
        bottom:20px;
        cursor:pointer;
        width:55px;
        height:55px;
        background-color:#bdd653;
        display:none;
        -webkit-border-radius:100%;
        -moz-border-radius:100%;
        border-radius:100%;
        line-height: 68px;
        text-align: center;
        cursor: pointer;
        display: inline-block;
        -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
    z-index: 9;
    }
    #scroll:hover{
        background: #88a725;
    }
    #scroll i{
        color: #fff;
        font-size: 44px;
        -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    }
    
    #scroll:hover i{
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    }
    
    
    
    
    /* navbar*/
    
    .navbar-brand {
        padding: 0;
        margin: 0;
        width: 170px;
        display: inline-block;
    }
    
    .navbar {
        background: none;
        margin: 0;
        padding: 0;
        min-height: inherit;
        height: inherit;
        border: none;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0;
    }
    
    .navbar-nav li {
        position: relative;
        list-style: none;
    }
    
    .navbar-nav>li {
        margin: 0 15px;
        padding: 20px 0;
        position: relative;
    }
    
    .navbar-nav>li:last-child {
        margin-right: 0;
    }
    
    .navbar-nav>li:first-child {
        margin-left: 0;
    }
    
    .navbar-nav>li>a {
        color: #000;
        font-size: 16px;
        line-height: 1.2;
        position: relative;
        font-weight: normal;
    }
    
    .navbar-nav>li>a:after {
        content: '';
        display: block;
        width: 0;
        height: 2px;
        background: #3B61DC;
        /* opacity: 0; */
        position: absolute;
        bottom: -10px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    
    .navbar-nav>li.current-menu-item>a,
    .navbar-nav>li>a:hover,
    .navbar-nav>li.menu-item-has-children:hover>a {
        color: #3B61DC;
    }
    
    .navbar-nav>li.current-menu-item>a:after,
    .navbar-nav>li>a:hover:after,
    .navbar-nav>li.menu-item-has-children:hover>a:after {
        width: 100%;
    }
    
    
    /* navbar submenu*/
    
    .sub-menu {
        background: #fff;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        display: none;
        border: 1px solid rgba(0, 0, 0, 0.15);
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0;
        width: 140px;
        padding: 0;
    }
    
    .sub-menu>li>a {
        color: #000;
        display: block;
        width: 100%;
        padding: 6px 10px;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.2;
    }
    
    .sub-menu>li {
        width: 100%;
        display: block;
    }
    
    .sub-menu>li>a:hover,
    .sub-menu>li.menu-item-has-children:hover>a,
    .sub-menu>li.current-menu-item>a {
        background: #eab71e;
        color: #fff;
    }
    
    
    
    .navbar-nav>li:last-child>.sub-menu {
        right: 0;
        left: inherit;
        padding: 0;
    }
    
    /* 2nd level submenu */
    .navbar-nav>li>.sub-menu .sub-menu {
        position: absolute;
        left: 100%;
        top: 0;
    }
    
    
    /* for push-menu */
    .navbar-collapse .navbar-toggler{
        display: none;
    }
    #navoverlay{
           display: block;
           pointer-events: none;
           position: fixed;
           left: 0;
           top: 0;
           width: 100%;
           height: 100%;
           background: transparent;
           margin: 0;
           padding: 0 !important;
           z-index: 99;
           -webkit-transition: background-color 0.5s linear;
            transition: background-color 0.5s linear;
    }
    /* for push-menu end*/
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    /* ========== responsive css =========== */
    
    @media(min-width:1025px) {
        /* navbar*/
        .navbar-nav .clickD {
            display: none;
        }
        .navbar-nav li.menu-item-has-children:hover>.sub-menu {
            display: block;
        }
    }
    
    
    @media(max-width:1600px) {
    
    }
    
    @media(max-width:1024px) {
        /* navbar*/
        .navbar-nav .clickD {
            position: absolute;
            right: 0;
            top: 17px;
            width: 20px;
            height: 20px;
           // background: url(images/sort-down.svg) center center no-repeat;
            display: block;
            background-size: 10px;
            cursor: pointer;
        }
        .navbar-nav .clickD.toggled{
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
        }
        .sub-menu > li.menu-item-has-children .clickD{
            top: 8px;
            right: 5px;
            -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
        }
        .sub-menu > li.menu-item-has-children .clickD.toggled{
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
        }
    
        .navbar-nav >li.menu-item-has-children {
            padding-right: 20px;
        }
        .sub-menu >li.menu-item-has-children > a{
            padding-right: 20px;
        }
        
        .sub-menu.show {
            display: block;
        }
    
        .navbar-nav > li{
            padding-top: 15px;
            padding-bottom: 15px;
        }
    
        .navbar-nav>li.current-menu-item>a:after,
        .navbar-nav>li>a:after,
        .navbar-nav>li.menu-item-has-children>a:after{
            width: 100%;
            opacity: 0;
        }
    
        .navbar-nav>li.current-menu-item>a:after,
        .navbar-nav>li>a:hover:after,
        .navbar-nav>li.menu-item-has-children:hover>a:after {
            width: 100%;
            opacity: 1;
        }
    
    /* navbar end*/
    
    
    }
    
    @media (max-width: 991px) {
        /* navbar*/
        .navbar-toggler {
            position: relative;
            width: 33px;
            margin-left: auto;
            height: 28px;
            padding: 0;
            outline: none !important;
            border: none !important;
            box-shadow: none !important;
        }
        .stick,
        .stick:after,
        .stick:before {
            width: 30px;
            height: 2px;
            background: #000;
            position: absolute;
            left: 0;
            top: auto;
            transition: all 0.6s;
            border-radius: 5px;
        }
        .stick:before {
            content: '';
            top: -10px;
            left: 0;
        }
        .stick:after {
            content: '';
            top: 10px;
            left: 0;
        }
        .stick.open {
            transform: translateX(-50px);
            background: transparent;
        }
        .stick.open:before {
            transform: rotate(45deg) translate(42px, -28px);
            left: 2px;
        }
        .stick.open:after {
            transform: rotate(-45deg) translate(42px, 28px);
            left: 2px;
        }
    
        .navbar-nav .clickD {
            top: 8px;
            right: 10px !important;
            -webkit-transform: rotate(0) !important;
            transform: rotate(0) !important;
        }
        .navbar-nav .clickD.toggled{
            -webkit-transform: rotate(180deg) !important;
            transform: rotate(180deg) !important;
        }
        .sub-menu,
        .navbar-nav>li>.sub-menu .sub-menu {
            position: relative;
            width: 100%;
            left: inherit;
            top: inherit;
            border: none;
            right: inherit;
        }
        .navbar-nav>li.menu-item-has-children {
            padding-right: 0;
        }
        .navbar-nav>li {
            margin: 0;
            padding: 0;
        }
        .navbar-nav li.menu-item-has-children > a{
            padding-right: 30px !important;
        }
        .navbar-nav>li>a {
            padding: 8px 20px;
            display: inline-block;
            width: 100%;
        }
        .sub-menu>li>a {
            padding-left: 40px;
        }
        .sub-menu .sub-menu>li>a {
            padding-left: 60px;
        }
        .sub-menu .sub-menu .sub-menu>li>a {
            padding-left: 80px;
        }
        
        .navbar-nav>li>a:after{
            bottom: 0;
        }
     /* navbar end*/
    
    /* push nav */
    
    .navbar-collapse{
        background: #fff;
        position: fixed;
        top: 0;
        height: 100% !important; 
        width: 290px;
        overflow-y: auto;
        transition: inherit !important;
        right: 0;
        margin: 0;
        display: block !important;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-transition: transform 0.2s ease-in-out !important;
        transition: transform 0.2s ease-in-out !important;
        z-index: 100;
    }
    .navbar-collapse.show,
    .navbar-collapse.collapsing{
        display: block !important;
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-transition: transform 0.2s ease-in-out !important;
        transition: transform 0.2s ease-in-out !important;
    }
    .navbar-collapse .navbar-nav{
        margin: 80px 0 0 !important;
        padding: 0 0 40px 0;
    }
    .navbar-collapse .navbar-toggler{
        display: block;
        right: 20px;
        top: 20px;
        position: absolute;
    }
    #navoverlay.open{
    
       background-color: rgba(0,0,0,0.4);
       pointer-events: all;
       -webkit-transition: background-color 0.5s linear;
       transition: background-color 0.5s linear;
    }
    body.open-nav,
    html.open-nav{
        height: 100%;
        overflow: hidden !important;
    }
    /* push nav end */
    
    
    
    
    
    
       
    }
    
    @media (max-width: 767px) {
        /* images alignment for wordpress content pages */
        .alignright,
        .alignleft,
        .aligncenter {
            float: none;
            clear: both;
            display: block;
            margin-left: auto;
            margin-right: auto;
            max-width: 100%;
            clear: both;
            margin-bottom: 24px;
        }
      
    }
    
    @media (max-width: 575px) {
    }
    
    @media (max-width: 479px) {
      .body-active{
          #root{
          height: 100vh;
          overflow: hidden;
          }
      }
    }
    

    .cursor{
        cursor: pointer;
    }

    .errText{
        color:red !important;
    }

    .linkText{
     color: #2196f3;
       margin-top: 1px;
        text-decoration: none;
        font-size: 14px;
    }

    .rotate{
        transform: rotate(180deg); 
    }

    .rotate-0{
        transform: rotate(360deg);
    }

    .textblack{
        color:black
    }

    // .capitalCapital{

    // }
    #mui-10{ margin-left: 20px !important;}
    .MuiTooltip-popper .MuiTooltip-tooltip{
        margin-right: -0px !important;
        margin-top: -2x !important;
        padding: 5px 10px !important;
        h1{font-size: 10px;
            font-weight: 500 !important;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4 !important;
            letter-spacing: normal;
            text-align: left;
            color: #fff !important;}
      }

      .MuiTooltip-arrow{ margin-top: -2px !important;}