.table-top-border {
  border-radius: 5px 5px 0 0;
  border: 1px solid #e0e0e0;
}

.top-tab ul {
  list-style-type: none;
  margin: 0px;
  margin-bottom: 8px;
  display: flex;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);

  li {
    display: flex;
    list-style-type: none;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.4px;
    justify-content: left;
    align-items: center;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.6);

    a {
      padding: 0px 50px;
      color: rgba(0, 0, 0, 0.6);
      text-decoration: none;
      display: flex;
      flex-wrap: nowrap;
      height: 100%;
      align-items: center;
    }

    .active {
      color: #2196f3;
      text-decoration: none;
      border-bottom: 1px solid #2196f3;
    }
  }

  li:first-child {
    padding-left: 0px;
  }
}

.dashboard-top {
//   margin-bottom: 50px;

  @media (max-width: 1000px) {
    margin-bottom: 20px;
  }
}

.first-tab {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.first-text {
  // font-size: 42px;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 45px;
  letter-spacing: 0.25px;
  text-align: left;
  color: #000;
  @media (max-width: 768px) {
    font-size: 20px;
    font-weight: 21px;
  }
  @media (max-width: 420px) {
    font-size: 14px;
    font-weight: 20px;
  }

  .middle-space {
    margin-left: 25px;
    margin-right: 25px;
  }
}

.left-space {
  margin-right: 3px !important;
}

.top-tab {
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 20px;
  position: relative;

  @media (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }

  .button-right {
    position: absolute;
    right: 0;
    top: 0;
    border: 0px;
    background: transparent;
    @media (max-width: 950px) {
      top: -50px !important;
      right: 0px !important;
      }
      @media (max-width:550px) {
        top: -78px !important;
        right: 0px !important;
        }
    // @media (max-width: 550px) {
    //   right: -5px;
    //   top: -78px;
    // }
  }
}

.second-tab button {
  margin-bottom: 0px;
}

.second-tab .css-c87k6u {
  margin-bottom: 0px;
}

.second-tab .css-1rb6bbl {
  margin-bottom: 0px;

  @media (max-width: 500px) {
    min-width: auto;
  }
}

.dashboard-m .css-iocxey-MuiPaper-root {
  border: 0px;
  box-shadow: none;
  text-align: left;
}

.dash-board-t {
  border-radius: 5px;
  border: solid 1px #e0e0e0;
  background-color: #fff;

  .space-main {
    padding: 15px;
    min-height: 124px;
  }

  .time-text {
    font-size: 48px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 52px;
    letter-spacing: normal;
    text-align: left;
    color: #757575;
    display: block;
    @media (max-width: 1000px) {
      font-size: 22px;
      line-height: 24px;
    }
    @media (max-width: 700px) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  .r-text {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.1px;
    text-align: left;
    color: #000;
    display: block;
  }

  .css-1dpyjwv-MuiLinearProgress-root {
    border-radius: 0px 0 5px 5px;
    height: 24.6px;
  }

  .css-1dpyjwv-MuiLinearProgress-root .MuiLinearProgress-bar {
    border-radius: 0px 20px 20px 20px;
  }

  .css-1dpyjwv-MuiLinearProgress-root {
    background: #e0e0e0;
  }

  .doble-icon {
    color: #4caf50;
  }
}

.blue-bar {
  height: 24.6px !important;
  border-radius: 0px 0px 5px 5px;
}

.mid {
  position: relative;

  .MuiLinearProgress-root {
    background-color: #e0e0e0 !important;
    border-radius: 0px 0px 5px 5px !important;
    height: 24.6px;
  }

  .red-bar span span {
    border-radius: 0px 20px 20px 20px !important;
  }

  // .css-cutjs6-MuiLinearProgress-root.MuiLinearProgress-colorPrimary {
  //     border-radius: 0px 0 5px 5px;
  //     height: 24.6px;
  // }

  // .css-1dpyjwv-MuiLinearProgress-root .MuiLinearProgress-bar {
  //     border-radius: 0px 20px 20px 20px;
  // }

  // .css-cutjs6-MuiLinearProgress-root {
  //     background: #e0e0e0;

  // }
}

.g-image {
  position: absolute;
  left: -1px;
  bottom: -1px;
  width: 101%;

  @media (max-width: 620px) {
    position: relative;
  }
}

.right-graph {
  height: 151px;
  @media (max-width: 1000px) {
    height: auto;
  }
}

.overview-main {
  border: solid 1px rgba(0, 0, 0, 0.12);
  margin-top: 15px;
  width: 100%;
  border-radius: 5px;
  background-color: none;
  background: none;

  .overview-t {
    background-color: none;
    background: none;
    border-bottom: solid 1px rgba(0, 0, 0, 0.12);
    border-radius: 0px;
    font-size: 20px;
    width: 100%;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    box-shadow: none;
  }

  .pile-m {
    padding: 15px 0;
  }

  .pile-text {
    border-right: solid 1px rgba(0, 0, 0, 0.12);
    border-radius: 0px;
    padding-bottom: 35px;
  }

  .pile-textright {
    border-right: 0px;
  }

  .pile-text-h {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.66;
    letter-spacing: 1px;
    text-align: left;
    padding-left: 15px;
    color: #000;
    padding-bottom: 0px;
    padding-top: 0px;
    box-shadow: none;
    @media (max-width: 1000px) {
      font-size: 12px;
      line-height: 15px;
    }
  }
}

.pile-text ul {
  width: 100%;
  padding-left: 15px;
  display: flex;

  li {
    display: inline-block;
    width: 50%;

    .heading-text1 {
      font-size: 30px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 35px;
      letter-spacing: 0.25px;
      text-align: left;
      color: #757575;
      display: block;
      @media (max-width: 1000px) {
        font-size: 14px;
        line-height: 18px;
      }
      @media (max-width: 700px) {
        font-size: 18px;
        line-height: 20px;
      }
      sub {
        margin: 0px;
        bottom: 0;
      }
    }

    .heading-textNew {
      font-size: 22px !important;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 35px;
      letter-spacing: 0.25px;
      text-align: left;
      color: #757575;
      display: block;
      @media (max-width: 1000px) {
        font-size: 14px;
        line-height: 18px;
      }
      @media (max-width: 700px) {
        font-size: 18px;
        line-height: 20px;
      }
      sub {
        margin: 0px;
        bottom: 0;
      }
    }

    .heading-text2 {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 18px;
      letter-spacing: 0.1px;
      text-align: left;
      color: #000;
      display: block;
      @media (max-width: 1000px) {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
}

.TabPanelUnstyled-root {
  font-family: "Roboto", sans-serif !important;
}

.TabUnstyled-root {
  font-family: "Roboto", sans-serif !important;
}

.tab-b-main {
  display: flex;
  flex-wrap: nowrap;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);

  @media (max-width: 768px) {
    width: 100%;
    flex-wrap: wrap;
  }

  .tab-b-left {
    width: 20%;
    border-radius: 0px;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    padding-left: 15px;
    color: #000;
    display: flex;
    align-items: center;
    padding-bottom: 4px;
    @media (max-width: 1200px) {
      font-size: 15px;
      line-height: 20px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .tab-b-right {
    width: 70%;

    @media (max-width: 768px) {
      width: 100%;
    }

    button {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: 0.4px;
      text-align: center;
      color: rgba(0, 0, 0, 0.6);
      border-bottom: 2px solid #fff;
      margin-bottom: 5px;

      @media (max-width: 1200px) {
        font-size: 12px;
        padding: 5px;
      }

      @media (max-width: 350px) {
        font-size: 10px;
        padding: 0px;
        margin: 0px;
      }
    }

    .css-be37l3:hover {
      color: #0072e5;
      border-bottom: 2px solid #2196f3;
      border-radius: 0px;
    }

    .css-be37l3.Mui-selected {
      color: #0072e5;
      border-bottom: 2px solid #2196f3;
      border-radius: 0px;
    }

    .css-1rb6bbl {
      margin-bottom: 0px;
    }
  }
}

.second-tab {
  padding-left: 25px;

  @media (max-width: 768px) {
    padding-left: 0px;
  }
}

.b-tab-con {
  padding: 40px 25px;

  .time-text-b {
    font-size: 48px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 52px;
    letter-spacing: 0.25px;
    text-align: left;
    color: #757575;
    display: block;
    @media (max-width: 1300px) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  .r-text-b {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.1px;
    text-align: left;
    color: #000;
    display: block;
  }

  sub {
    bottom: 0;
  }
}

.right-panel {
  border-radius: 5px;
  border: solid 1px #e0e0e0;
  background-color: #fff;
  padding: 0px;
  height: 100%;
  display: block !important;
  position: relative;

  h4 {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    padding: 8px;
    margin: 0px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.12);
    width: 100%;
  }

  ul {
    list-style-type: none;
    width: 100%;

    li {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: 0.17px;
      text-align: left;
      color: rgba(0, 0, 0, 0.87);
      border-bottom: 1px solid rgba(0, 0, 0, 0.04);
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 8px;
      @media (max-width: 1200px) {
        font-size: 14px;
        line-height: 18px;
      }
      @media (max-width: 700px) {
        font-size: 18px;
        line-height: 15px;
      }
      .left-text {
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.17px;
        text-align: left;
        color: rgba(0, 0, 0, 0.87);
        @media (max-width: 1000px) {
          font-size: 14px;
          line-height: 18px;
        }
        @media (max-width: 700px) {
          font-size: 18px;
          line-height: 15px;
        }
      }

      .left-text1 {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.9;
        letter-spacing: 0.16px;
        text-align: right;
        color: rgba(0, 0, 0, 0.6);
        @media (max-width: 1000px) {
          font-size: 14px;
          line-height: 18px;
        }
        @media (max-width: 700px) {
          font-size: 18px;
          line-height: 15px;
        }
      }
    }
  }

  .bar-icon {
    border-radius: 4px;
    border: solid 1px #2196f3;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.73;
    letter-spacing: 0.46px;
    text-align: center;
    width: 80% !important;
    margin: 10px auto;
    left: 10%;
    color: #2196f3;
    bottom: 0px;
    position: absolute;

    @media (max-width: 968px) {
      position: relative;
      padding-bottom: 10px;
    }
  }
  .bar-icon2 {
    border-radius: 4px;
    border: solid 1px #2196f3;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.73;
    letter-spacing: 0.46px;
    text-align: center;
    width: 80% !important;
    // margin: 10px auto;
    left: 10%;
    color: #2196f3;
    bottom: 0px;
    // padding: 10px 20px !important;
    // position: absolute;

    @media (max-width: 968px) {
      position: relative;
      padding-bottom: 10px;
    }
  }
}
 .colorBlue{
   color:#2196f3
 }


.down-arrow {
  color: #4caf50;
}

.p-icon {
  background: #e31b0c;
  color: #fff;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  text-align: center;
  margin-bottom: 5px;
}

.table-container thead {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.15);
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}

.table-container tbody th {
  border-bottom: solid 1px rgba(0, 0, 0, 0.09);
}

.table-container tbody td {
  border-bottom: solid 1px rgba(0, 0, 0, 0.09);
}

.middle-space {
  margin-left: 5px !important;
  margin-right: 15px !important;
  @media (max-width: 1000px) {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
}
.c-head {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71 !important;
  letter-spacing: 0.17px !important;
  @media (max-width: 1200px) {
    padding: 5 !important;
    font-size: 12px !important;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 14px !important;
    letter-spacing: 1px !important;
  }
}
@media (max-width: 1000px) {
  .table-container tbody td {
    padding: 5px !important;
    font-size: 12px !important;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 14px !important;
  }
}
.active-button11 {
  justify-content: flex-start;
  align-items: center;
  padding: 6px 14px;
  cursor: pointer;
  font-size: 13px !important;
  border-radius: 16px !important;
  border: 0;
  // width: 65px !important;
  min-width: 65px;
  text-align: center !important;
  color: #689d6b !important;
  background-color: #eef7ee !important;
}
.active-button12 {
  justify-content: flex-start;
  align-items: center;
  padding: 6px 14px;
  cursor: pointer;
  font-size: 13px !important;
  border-radius: 16px !important;
  border: 0;
  min-width: 65px;
  // width: 65px !important;
  text-align: center !important;
  color: #323232 !important;
  background-color: #eee !important;
}

.active-buttonRed {
  justify-content: flex-start;
  align-items: center;
  padding: 6px 14px;
  cursor: pointer;
  font-size: 13px !important;
  border-radius: 16px !important;
  border: 0;
  // width: 65px !important;
  text-align: center !important;
  color: #323232 !important;
  background-color: #feedeb !important;
  
}

.redText{
  color:#e31b0c;
}

.edit-con fieldset {
  border: 0px !important;
}

.modal-main div {
  box-shadow: none !important;
}
.edit-con-p > div {
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
  padding-left: 7px;
}

.edit-con-p > div > div > div > div {
  padding-top: 6px;
}
.button-right-2{ 
  position: absolute !important; right:39px !important ; top: 5px !important;
  @media (max-width: 950px) {
  top: -50px !important;
  right: 30px !important;
  }
  @media (max-width:550px) {
    top: -78px !important;
    right: 30px !important;
    }
}
@media (max-width: 950px) {
  .button-po{ position: relative; width: 50%; }
}
@media (max-width:768px) {
  .button-po{ position: relative; width: 100%;}
}