.main-container {
  max-width: 100%;
  margin: 0px auto;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 1400px) {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .brade-camp {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    padding-top: 50px;

    a {
      color: #2196f3;
      text-decoration: none;
    }

    span {
      display: inline-block;
    }
  }

  .brade-camp2 {
   
    padding-top: 25px;
    padding-bottom: 25px;
   
  }

  .dashboard-contain {
    margin: 0px auto;
    // padding: 0 65px;
      padding: 0 10px;
    padding-bottom: 60px;

    @media (max-width: 1000px) {
      padding: 0 15px;
    }

    @media (max-width: 700px) {
      padding: 0 0px;
    }

    .dashboard-top {
      justify-content: space-between;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }
    .dashboard-top2 {
      // justify-content: space-between;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }

    .schedule-button {
      background: #2196f3;
      padding: 4px 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.69;
      letter-spacing: 0.46px;
      text-align: left;
      color: #fff;
      border: 0px;
      border-radius: 40px;
    }

    h1 {
      font-size: 34px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.24;
      letter-spacing: 0.25px;
      text-align: left;
      color: #000;
      margin-top: 50px;
      // margin-bottom: 30px;

      @media (max-width: 1200px) {
        margin-top: 20px;
        font-size: 20px;
        line-height: 24px;
      }
    }

    // .dashboard-main table {
    //     width: 100%;

    //     thead tr {
    //         box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.15);
    //         box-shadow: 1px 5px 4px -5px rgba(0, 0, 0, 0.32);
    //         -webkit-box-shadow: 1px 5px 4px -5px rgba(0, 0, 0, 0.32);
    //         -moz-box-shadow: 1px 5px 4px -5px rgba(0, 0, 0, 0.32);
    //         border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    //         padding-bottom: 20px;

    //         th {
    //             font-size: 14px;
    //             font-weight: 500;
    //             font-stretch: normal;
    //             font-style: normal;
    //             line-height: 1.71;
    //             letter-spacing: 0.17px;
    //             text-align: left;
    //             color: rgba(0, 0, 0, 0.87);
    //             padding: 16px 10px;
    //         }
    //     }

    //     tbody tr {
    //         border-bottom: solid 1px rgba(0, 0, 0, 0.09);

    //         td {
    //             font-size: 14px;
    //             font-weight: normal;
    //             font-stretch: normal;
    //             font-style: normal;
    //             line-height: 1.43;
    //             letter-spacing: 0.15px;
    //             text-align: left;
    //             padding: 16px 10px;
    //             color: rgba(0, 0, 0, 0.87);

    //             .range-silder {
    //                 width: 60%;
    //                 background: #eeeeee;
    //                 display: inline-block;
    //                 border-radius: 40px;
    //                 height: 12px;
    //                 margin-right: 5px;

    //                 .range-silder-p {
    //                     background: #4caf50;
    //                     height: 12px;
    //                     width: 70%;
    //                     display: flex;
    //                     border-radius: 40px;
    //                 }
    //             }
    //         }

    //     }
    // }
    .inprogress-button {
      justify-content: flex-start;
      align-items: center;
      padding: 6px 14px;
      cursor: pointer;
      font-weight: normal !important;
      font-size: 13px !important;
      border-radius: 16px !important;
      color: rgba(14, 82, 2, 82%);
      background-color: #eef7ee;
      letter-spacing: 0px !important;
    }

    .scheduled-button {
      justify-content: flex-start;
      align-items: center;
      padding: 6px 14px;
      cursor: pointer;
      font-size: 13px !important;
      border-radius: 16px;
      font-weight: normal !important;
      border: 0px;
      color: #323232;
      background-color: #eee;
      letter-spacing: 0px !important;
      @media (max-width: 1000px) {
        padding: 6px 5px;
        font-size: 11px !important;
      }
    }

    .completed-button {
      justify-content: flex-start;
      align-items: center;
      padding: 6px 14px;
      font-size: 13px !important;
      font-weight: normal !important;
      border-radius: 16px;
      color: #2196f3;
      background-color: #e9f5fe;
      letter-spacing: 0px !important;
      @media (max-width: 1000px) {
        padding: 6px 5px;
        font-size: 11px !important;
      }
    }
  }
}

.modal-main {
  & h2 {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.15px;
    text-align: left;
    color: #000;
    text-transform: capitalize;
  }

  .cross-button {
    background: none;
    border: 0px;
    float: right;
    color: rgba(0, 0, 0, 0.54);
  }

  .css-a88p61-MuiInputBase-root-MuiInput-root:before {
    display: none;
  }

  .css-cnd76u-MuiPaper-root {
    box-shadow: none;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.15px;
    text-align: left;
    color: rgba(0, 0, 0, 0.6);
    padding: 0px;
    border-radius: 4px;
    border: solid 1px #323232;
  }

  .css-a88p61-MuiInputBase-root-MuiInput-root {
    width: 100%;
    padding: 0px 0;
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline .css-173wfxe > span {
    display: none !important;
  }

  .css-1z7n62 span {
    display: none !important;
    opacity: 0;
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: 0px;
    //border-bottom: 1px solid;
  }

  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    box-shadow: none;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.15px;
    text-align: left;
    color: rgba(0, 0, 0, 0.6);
    padding: 0px;
    top: -5px;
  }

  .css-1869usk-MuiFormControl-root {
    margin: 0px;
    width: 100%;
  }

  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 8px 16px;
  }

  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    width: 100%;
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    width: 100%;
  }

  .last > div {
    border-radius: 5px !important;
    border: 1px dashed #2196f3 !important;

    div {
      display: none !important;
    }

    label {
      width: 100%;
      justify-content: center;
      /* height: 100%; */
      align-items: center;
      display: flex;
      // height: 55px;
    }

    .css-a88p61-MuiInputBase-root-MuiInput-r {
      width: auto !important;
    }

    .css-a88p61-MuiInputBase-root-MuiInput-root {
      display: none;
    }

    .MuiIconButton-root span {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: 0.15px;
      text-align: left;
      color: rgba(0, 0, 0, 0.54);
    }
  }

  .schedule-b {
    border-radius: 30px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.2);
    background-color: #2196f3;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: 0.46px;
    text-align: left;
    color: #fff;
    border: 0px;
    width: 100%;
  }
  .schedule-b:hover {
    background-color: #2196f3 !important;
  }

  .last-modal .css-cnd76u-MuiPaper-root {
    border: 0px;
  }

  .last-modal .css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
    color: #000;
  }

  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.15px;
    text-align: left;
    color: rgba(0, 0, 0, 0.6);
  }
}

@media (max-width: 768px) {
  .css-1ygcj2i-MuiTableCell-root {
    padding: 8px;
    font-size: 12px;
    line-height: 15px;
  }

  .css-1ygcj2i-MuiTableCell-root {
    padding: 8px !important;
    font-size: 12px !important;
    line-height: 15px !important;
  }

  .css-1ex1afd-MuiTableCell-root {
    padding: 8px !important;
    font-size: 12px !important;
    line-height: 15px !important;
  }

  .dashboard-main table tbody tr td:nth-child(1) {
    font-weight: bold !important;
    //border: 1px solid red;
  }

  .css-34nofg-MuiTableRow-root td:nth-child(2) {
    // width: 20%;
  }

  .css-34nofg-MuiTableRow-root th:nth-child(2) {
    // width: 20%;
  }
}

.main-table {
  box-shadow: none !important;

  table thead {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.15);
    border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  }

  table td {
    border-bottom: solid 1px rgba(0, 0, 0, 0.09);
    @media (max-width: 1000px) {
      font-size: 12px !important;
      padding: 5px !important;
    }
  }
}

.button-last {
  background: transparent;
}

.group-code-main {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.group-code-main input[type="text"] {
  padding-left: 15px;
  padding-right: 0px !important;
  padding-bottom: 10px;
  padding-top: 10px;
  border: solid 1px #323232;
  border-radius: 4px;
}

.p-date input[type="tel"] {
  height: 20px !important;
  padding: 13px;
}

@media (max-width: 1200px) {
  .p-date input[type="text"] {
    padding: 11px !important;
  }
}

.pile-location-main {
  border: 0px;
}

.group-code-main div {
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.group-code-main div div::after {
  display: none;
}

.group-code-main > div > div {
  padding-left: 8px !important;
  width: 100%;
  text-align: left;
  padding-right: 0px !important;
}

.group-code-main > div > div::before {
  display: none;
}

.modal-main fieldset {
  border: solid 1px #323232 !important;
}

.p-date div div label {
  background: #fff !important;
}

.pile-location-main div div label {
  background: #fff !important;
}

.shifts div div label {
  background: #fff !important;
  top: -5px;
}

.vessel-voyage {
  border: 0px;
}

.con-number div {
  border: solid 1px #323232 !important;
  border-radius: 4px;
}

.vessel-voyage > div {
  border: solid 1px #323232 !important;
  border-radius: 4px;
  min-height: 46px;
  text-align: left;
}
.vessel-voyage > div input[type="text"] {
  margin-top: 0px;
  padding: 0px;
  padding-left: 4px;
}
.vessel-voyage > div > div:after {
  display: none;
}
.vessel-voyage > div > div:before {
  display: none;
}
.pile-location-main {
  padding-top: 10px;
}
.vessel-voyage {
  padding-top: 10px;
}
.group-code-main {
  padding-right: 0px;
}
.p-date {
  padding: 16px 0 0 8px !important;
}
.p-date > div {
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0px;
}
.pile-location-main div div label {
  top: -5px;
}

.p-date > div > div label {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  text-align: left;
  top: -5px;
}
.p-date {
  padding-left: 8px;
}
.p-date > div > div {
  width: 100% !important;
}
// .p-date >div >div >div{ padding-right: 12px !important;}
.p-date > div > div > div:first-child {
  padding-right: 18px !important;
}
.p-date div:nth-child(3) {
  padding-right: 12px !important;
}
.p-date div:nth-child(4) {
  padding-left: 6px !important;
}
.pile-location-main > div > div > div {
  padding-top: 0px;
}
.pile-location-main > div > div > div div {
  padding-top: 11px;
  padding-bottom: 11px;
}
.shifts div div div div {
  padding: 12px;
}
.e-text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  text-align: left;
  @media (max-width: 1200px) {
    font-size: 14px;
    line-height: 18px;
  }
}
.con-number textarea {
  padding: 0px;
  // height: 60px !important;
  height: 100px !important;
  overflow-y: scroll !important;
}
.con-number {
  padding-top: 0px !important;
}
.errText {
  font-size: 12px;
  line-height: 15px;
}
.first-error {
  padding-left: 16px;
}
.t-space-one {
  padding-top: 0px !important;
}
.errText {
  color: red;
  float: left;
}
.group-main div div {
  width: 100%;
}
.group-main div div div:before {
  display: none !important;
}
.group-main div div div:after {
  display: none !important;
}
.group-main div .css-ercmji::after {
  display: none !important;
}
.group-main div .css-ercmji::before {
  display: none !important;
}
.group-main > div {
  border: 1px solid #323232 !important;
  border-radius: 4px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.shift-number-b {
  border: 0px !important;
}
.shift-number-b div {
  border: 0 !important;
  text-align: left !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  line-height: 15px;
  color: #000 !important;
  margin: 0px !important;
  padding: 0px;
}
.shift-one-b div {
  padding: 0px !important;
  margin: 0px !important;
  width: 100% !important;
}
.shift-one-b div:first-child {
  margin-right: 12px;
}
.shift-one-b div p {
  display: none !important;
}
.shift-one-b label {
  background: #fff !important;
}
.shift-one-b legend {
  background: #fff !important;
}
.modal-main1 {
  overflow-y: scroll !important;
  height: 600px !important;
}
.vessel-voyage-date > div {
  padding: 0px !important;
}
.vessel-voyage-date > div {
  margin: 0px !important;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
  padding-right: 0;
}
.vessel-voyage-date > div label {
  background: #fff !important;
}
.vessel-voyage-date > div Button {
  padding: 0px !important;
  margin: 0px !important;
}

.vessel-voyage-date div input[type="tel"] {
  padding-top: 11px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-bottom: 11px !important;
}
.last-con-number {
  margin-top: 12px !important;
}
.kick-off >span >span >span:first-child{ border: 1px solid red;}
.top-1{ margin-bottom: 25px;}
.terminal-main div div{ width: 100%; 
  label{ background: #fff;border-radius: 10px; font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.15px;
    text-align: left;
    color: rgba(0, 0, 0, 0.6) !important;}
}
.terminal-main2 div div{ width: 100%; 
  label{ background: #fff;border-radius: 10px; font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    letter-spacing: 0.5px;
    text-align: left;
    color: rgba(0, 0, 0, 0.6) !important;
    }
}
.motor-career{ padding-top: 20px;}
#simple-tabpanel-0{padding-top: 20px;}
.enable-kick{font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.15px;
  text-align: left;
  color: #000;}
  .periodic{font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 0.15px;
    text-align: left;
    color: #000;}
    .chef-valu{ display: inline-block; width: auto !important;font-family: Roboto;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: 0.16px;
      text-align: left;
      color: rgba(0, 0, 0, 0.87);}
    .react-tag-input::placeholder{font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.15px;
      text-align: left;
      color: rgba(0, 0, 0, 0.38);
    }
    .react-tag-input__input{font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  text-align: left;
  color: rgba(0, 0, 0, 0.38);
    }
    .m-c-recipents{ margin-left:6px !important;}
    
    #mui-10{ margin-left: 20px !important;}