.header-main {
  max-width: 100%;
  margin: 0px auto;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1400px) {
    max-width: 100%;
  }
  @media (max-width: 950px) {
    align-items: center;
    .menu-icon {
      margin-top: 20px;
    }
  }
  .logo {
    width: 148px;
    display: inline-block;
    margin-right: 50px;
    padding-top: 10px;
    svg {
      width: auto;
      height: auto;
    }
  }

  .top-nav {
    padding-top: 10px;

    & ul {
      list-style-type: none;
      display: inline-block;

      & li {
        display: inline-block;
        margin-right: 50px;

        @media (max-width: 1300px) {
          margin-right: 20px;
        }

        a {
          margin: 0.7px 0 0.7px 8.6px;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.15px;
          text-align: left;
          color: #000;
        }

        .top-icon {
          margin: 0 8.6px 0 0;
          padding: 4.5px 3.9px 4.5px 3.9px;
          border-radius: 3px;
          color: #fff;
          font-size: 30px;
          font-weight: normal;
        }

        .home-icon {
          background: #2789fb;
        }

        .quote-icon {
          background: #ca0707;
        }

        .book-icon {
          background: #f3810e;
          color: #fff;
        }

        .rates-icon {
          background: #4ba32c;
        }

        .dashedboard-icon {
          background: #b25f1a;
        }
      }
      & li:last-child {
        margin-right: 0px;
      }
    }
  }
}
.right-nav {
  ul {
    list-style-type: none;

    li {
      padding: 13px;
      display: inline-block;

      a {
        color: #000;
      }
    }
    li:last-child {
      padding-right: 0px;
    }
  }
}
.mobile-menu {
  display: none;
  width: 200px;
  z-index: 999;
  width: 300px;
  position: absolute;
  right: -300px;
  height: 100vh;
  background: #00528a;
  text-align: left;
  top: 0;
}
.ovelay-con {
  display: none;
}
.menu-icon {
  display: none !important;
}
@media (max-width: 1200px) {
  .header-main .top-nav ul li a {
    margin: 5px;
    font-size: 12px;
    letter-spacing: 0.1px;
    text-align: left;
    color: #000;
  }
  .right-nav ul li {
    padding: 13px 5px;
  }
}
@media (max-width: 968px) {
  .mobile-nav {
    display: none !important;
  }
  .body-active .mobile-menu {
    display: block;
    padding: 50px 25px;
    right: 0px;
  }
  .body-active .header-main .top-nav ul li {
    width: 100%;
    margin-bottom: 10px;
  }
  .body-active .header-main .top-nav ul li {
    color: #fff;
  }
  .header-main .top-nav ul li a {
    color: #fff;
  }
  .right-nav ul li a {
    color: #fff;
  }
  .body-active {
    height: 100vh;
    overflow: hidden;
  }
  .body-active .ovelay-con {
    display: block;
    position: absolute;
    width: 100%;
    z-index: 9;
    height: 100vh;
    background: #101a34;
    opacity: 0.7;
    left: 0;
  }
  .cancel-icon1 {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
  }
  .ancel-icon1 {
    color: white;
  }
  .menu-icon {
    display: block !important;
  }
}
