.appoinment-top {
  // justify-content: flex-start;
  // display: flex;
  // flex-wrap: nowrap;
  // align-items: center;
  width: 100%;
  position: relative;
  h1 {
    font-size: 42px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: 0.25px;
    text-align: left;
    color: #000;
    position: absolute;
    left: 0px;
    top: 0px;
  }
}
.appointment-tab-top {
  padding-left: 430px;
  padding-top: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  @media (max-width: 1200px) {
    padding-top: 20px;
    padding-left: 200px;
  }
  @media (max-width: 600px) {
    padding-left: 0px;
    padding-top: 60px;
  }
}
.appointment-tab-top button {
  border: 0px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.4px;
  text-align: center;
  padding: 10px 50px;
  color: rgba(0, 0, 0, 0.6);
  text-transform: uppercase;
  background: transparent !important;
  @media (max-width: 1200px) {
    padding: 0 25px;
  }
  @media (max-width: 600px) {
    font-size: 12px;
    padding: 0 10px;
  }
}
.appointment-tab-top .Mui-selected {
  color: #2196f3;
  border-bottom: 2px solid #2196f3;
}
.appointment-tab-top-con {
  padding-top: 20px;
}
.dashboard-appointment-con {
  padding-top: 10px;
}
.last-week-button {
  padding: 6px 16px !important;
  border-radius: 50px !important;
  border: 1px solid #2789fb !important;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.4px;
  text-align: left;
  color: #2789fb !important;
  margin: 0 10px !important;
  @media (max-width: 1200px) {
    letter-spacing: 0.1px !important;
    font-size: 11px !important;
    margin: 0 5px !important;
    padding: 6px 10px !important;
    min-height: 42px;
  }
  @media (max-width: 400px) {
    font-size: 10px !important;
  }
}
.last-month-button {
  padding: 6px 16px !important;
  border-radius: 50px !important;
  border: 1px solid #323232 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.71 !important;
  letter-spacing: 0.4px !important;
  text-align: left !important;
  color: #323232 !important;
  margin: 0 10px !important;
  @media (max-width: 1200px) {
    letter-spacing: 0.1px !important;
    font-size: 11px !important;
    margin: 0 5px !important;
    padding: 6px 10px !important;
    min-height: 42px;
  }
  @media (max-width: 400px) {
    font-size: 10px !important;
  }
}
.appiontment-middle-contain {
  padding: 50px 0;
}
.right-appointments-button {
  position: absolute !important;
  right: 0px;
  top: 60px !important;
  min-width: auto !important;
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: 20px;
  @media (max-width: 1300px) {
    top: 20px !important;
  }

  @media (max-width: 600px) {
    padding: 0px !important;
    margin: 0px;
  }
}
.top-appointment-con1 {
  h2 {
    font-size: 43px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #757575;
    text-transform: capitalize !important;
    margin-bottom: 0px;
    @media (max-width: 1300px) {
      font-size: 24px;
    }
  }
  p {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.1px;
    text-align: left;
    color: #000;
    display: flex;
    align-items: center;
  }
  .green-button1 {
    width: 10px;
    height: 10px;
    background-color: #4caf50;
    border-radius: 10px;
    display: inline-block;
    margin-left: 10px;
  }
}
.missed-appiontment {
  padding: 20px 0;
  h2 {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    text-transform: capitalize;
  }
}
.mui-right-tab {
  //display: inline-block;
  display: flex;

  div {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  @media (max-width: 1200px) {
    input[type="text"],
    input[type="tel"],
    input[type="password"],
    input[type="email"],
    select,
    label {
      font-size: 12px !important;
    }
  }
  @media (max-width: 1100px) {
    margin-bottom: 10px;
    width: 100%;
  }
}
.dashboard-appointment-contab2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.appointments-dashboard-table {
  padding-top: 25px;
}
.new-exception {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.15px;
  text-align: left;
  color: #000;
  display: flex;
  justify-content: space-between;
  text-transform: capitalize !important;
}
.chef-com {
  padding: 10px;
  div {
    padding: 0px 10px 0px 0;
  }
}
.grant-exception {
  background: #2196f3 !important;
  padding: 4px 15px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: 0.46px;
  text-align: left;
  color: #fff !important;
  width: 100% !important;
  border: 0px;
  border-radius: 40px !important;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.search-box {
  display: inline-block;
  position: relative;
  @media (max-width: 1100px) {
    width: 50%;
  }
  @media (max-width: 600px) {
    width: 100% !important;
    form {
      margin-bottom: 15px;
    }
  }

  form div {
    margin: 0px;
  }
}
.clear-icon{
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 20px;
  color: #323232;
  width: 25px !important;
  height: 25px !important;
}
.search-icon {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 20px;
  color: #323232;
  width: 25px !important;
  height: 25px !important;
}
.calendar-icon {
  margin-left: 10px;
}
.m-t {
  border: 0px !important;
  border-radius: 20px !important;
}
//  #kk{ border: 0px !important;}
.update-button11 {
  background: #2196f3 !important;
  padding: 4px 15px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  box-shadow: none !important;
  border: 0px !important;
  letter-spacing: 0.46px;
  text-align: left;
  color: #fff !important;
  border: 0px;
  border-radius: 40px !important;
  width: 100% !important;
}
.b-space {
  margin-bottom: 20px;
}
.missed-border1 {
  border: solid 1px #eee;
  border-radius: 5px;
}
.missed-heading {
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  padding-left: 20px;
  padding-bottom: 20px;
}
.t-width {
  width: 200px;
  @media (max-width: 1300px) {
    width: 150px !important;
  }
  @media (max-width: 600px) {
    width: 25% !important;
  }
  @media (max-width: 400px) {
    width: 100% !important;
  }
}
.appoint-type {
  width: 180px;
  margin-left: 10px !important;
  @media (max-width: 1200px) {
    width: 150px;
  }
  @media (max-width: 600px) {
    width: 40% !important;
  }
  @media (max-width: 400px) {
    width: 100% !important;
    margin-left: 0px !important;
  }
}
// .search-box form div {
//   width: 200px !important;
//   @media (max-width: 1100px) {
//     width: 100% !important;
//   }

//   @media (max-width: 1200px) {
//     width: 150 !important;
//   }
//   @media (max-width: 600px) {
//     width: 100% !important;
//   }
// }
.search-box form {
  margin-right: 10px;
  @media (max-width: 600px) {
    margin-right: 0px;
  }
}
.shift-width {
  width: 120px;
  margin-left: 10px !important;

  @media (max-width: 600px) {
    width: 25% !important;
  }
  @media (max-width: 400px) {
    width: 100% !important;
    margin-left: 0px !important;
  }

  // div div{ padding: 13px !important;}
}

.appoint-space1 {
  border-radius: 5px !important;
  padding: 20px !important;
  border: solid 1px #e0e0e0 !important;
  box-shadow: none !important;
}
@media (max-width: 600px) {
  .right-bg-t {
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
@media (max-width: 380px) {
  .mui-right-tab {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .custom-button {
    width: 100%;
    font-size: 12px !important;
    display: inline-block;
    min-height: auto;
    margin-bottom: 10px !important;
  }
  .middle-month-button {
    width: 46%;
    font-size: 12px !important;
    display: inline-block;
    min-height: auto;
  }
  .last-month-button-w {
    width: 46%;
    font-size: 12px !important;
    display: inline-block;
    min-height: auto;
  }
}
.chef-com12 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.chef-com1 div {
  margin-right: 12px;
}

@media (max-width: 400px) {
  #app-type-id {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}
.adhurnce-button {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  // line-height: 1.71;
  line-height: 17px;
  letter-spacing: 0.17px;
  text-align: left;
  color: rgba(0, 0, 0, 0.3);
  padding: 3px 4px;
  border-radius: 16px;
  background: #eef7ee !important;
  width: 45px;
  height: 24px;
  display: inline-block;
  text-align: center;
}
.main-table tr th {
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.71 !important;
  letter-spacing: 0.17px !important;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
}
.main-table tr td {
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.71 !important;
  letter-spacing: 0.17px !important;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
}
// .MuiDataGrid-columnHeadersInner .MuiDataGrid-cell:last-child{display: none;}
.pagination-t ul {
  justify-content: flex-end;
  padding: 10px 0;
  li button {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.43 !important;
    letter-spacing: 0.15px !important;
    text-align: left !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
}
.appoint-ment-dashboard thead tr th {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.17px;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
}
.appoint-ment-dashboard tbody tr th {
  font-family: Roboto;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.15px;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: solid 1px rgba(0, 0, 0, 0.09);
}
.appoint-ment-dashboard tr th svg {
  font-size: 20px !important;
  border: 0px;
}
.appoint-ment-dashboard tbody tr th:last-child button {
  min-width: auto !important;
  padding: 0px !important;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.54) !important;
}
.v2 thead {
  box-shadow: none !important;
}

@media (max-width: 700px) {
}
